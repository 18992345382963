<template>
  <div class="custom-header-top">
    <div class="container px-5 py-2">
      <a href="tel:8 800 560 060 00" class="custom-phone-link">
        8 800 560 060 00
      </a>
      <span class="custom-header-date">
        {{ getCurrentDate }}
      </span>
    </div>
  </div>
</template>


<script>

export default {
  name: 'HeadetTop',
  computed: {
    getCurrentDate() {
      const date = new Date()
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return date.toLocaleDateString('ru-RU', options).replace(' г.', '')
    }
  }
}
</script>