<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.00016 6.66667H1.3335V2.66867C1.3335 2.29933 1.63683 2 1.99483 2H14.0055C14.093 1.99982 14.1797 2.01704 14.2605 2.05067C14.3413 2.0843 14.4146 2.13366 14.4761 2.19589C14.5377 2.25812 14.5862 2.33196 14.619 2.41313C14.6517 2.49429 14.668 2.58116 14.6668 2.66867V6.66667H14.0002V13.334C14.0004 13.4212 13.9835 13.5076 13.9504 13.5883C13.9173 13.6689 13.8686 13.7423 13.8071 13.8041C13.7456 13.8659 13.6726 13.9151 13.5921 13.9487C13.5117 13.9823 13.4254 13.9997 13.3382 14H2.66216C2.57496 13.9997 2.48867 13.9823 2.40821 13.9487C2.32775 13.9151 2.2547 13.8659 2.19323 13.8041C2.13176 13.7423 2.08307 13.6689 2.04994 13.5883C2.01681 13.5076 1.9999 13.4212 2.00016 13.334V6.66667ZM12.6668 6.66667H3.3335V12.6667H12.6668V6.66667ZM2.66683 3.33333V5.33333H13.3335V3.33333H2.66683ZM6.00016 8H10.0002V9.33333H6.00016V8Z" fill="#323232"/>
  </svg>
</template>

<script>
export default {
  name: 'BoxIcon'
}
</script>