<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <logo />
      </b-navbar-item>
    </template>

    <template #start>
      <header-top />
      <b-navbar-item href="/rules">
        Регламент
      </b-navbar-item>
      <b-navbar-item :href="helpFile">
        Помощь
      </b-navbar-item>
      <b-navbar-item href="/contacts">
        Контакты
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <router-link to="/login" class="button is-normal border-button">
            <span>Войти</span>
          </router-link>
          <router-link to="sign-up" class="button is-normal main-button">
            <span class="icon is-small">
              <user-add-icon />
            </span>
            <span>Регистрация</span>
          </router-link>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>

import Logo from "@/components/icons/Logo.vue"
import UserAddIcon from "@/components/icons/UserAddIcon.vue"
import HeaderTop from "./HeaderTop.vue"

export default {
  name: 'Navbar',
  components: { Logo, UserAddIcon, HeaderTop }
}
</script>