<template>
  <header>
    <header-top />
    <div class="custom-header-nav">
      <div class="container p-5">
        <navbar-authorized v-if="isLoggedIn"/>
        <navbar v-else/>
      </div>

    </div>

  </header>
</template>

<script>
import HeaderTop from './HeaderTop.vue';
import Navbar from "./Navbar.vue";
import NavbarAuthorized from './NavbarAuthorized.vue';
import authService from "../../services/auth.service";

export default {
  name: "Header",
  components: { Navbar, HeaderTop, NavbarAuthorized },
  computed: {
    isLoggedIn() {
      return authService.isLoggedIn();
    },
  },
}
</script>
