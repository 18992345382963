<template>
  <section>
    <header-vue />

    <div class="container text-center-mobile-only pb-1 pl-5 pr-5 pt-5 mb-4">
      <div
        class="is-flex is-align-items-center is-justify-content-space-between"
      >
        <h2 class="main-title has-text-left has-text-centered-mobile">
          Тендеры
        </h2>
        <div>
          <b-switch size="is-small"></b-switch>
          <span>
            Cкрыть неактивные
          </span>
        </div>
      </div>
    </div>

    <tender-list-full />

    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
//import TenderListFull from "@/home/TenderListFull/TenderListFull.vue";

export default {
  components: { HeaderVue, FooterVue },
  name: "Tenders",
};
</script>

<style lang="scss" scoped>
</style>